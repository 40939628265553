import Button from "@/components/Common/Buttons/Button/Button";
import AboutButton from "@/components/Common/Buttons/AboutButton/AboutButton";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import PrinceRangeSlider from "@/components/Components/PriceRangeSlider/PriceRangeSlider";
import MarketplaceAd from "@/components/Components/MarketplaceAd/MarketplaceAd";
import PropertyAd from "@/components/Components/PropertyAd/PropertyAd";
import MarketplaceSortFilter from "@/views/MarketplaceCategoryPage/MarketplaceSortFilter/MarketplaceSortFilter";


export default {
    name: 'Favorites',
    components: {

        Button,
        CheckBox,
        PrinceRangeSlider,
        MarketplaceAd,
        PropertyAd,
        AboutButton,
        MarketplaceSortFilter
    },
    data() {
        return {
            filterContainerActive: false,
            activeGrid: 'properties',
            products: null,
            properties: null,

        }
    },
    methods: {
        toggleFilterContainer() {
            this.filterContainerActive = !this.filterContainerActive
        }
    },
    async mounted() {
        this.products = await this.$store.dispatch('favorites/getFavorites', 'product');
        this.properties = await this.$store.dispatch('favorites/getFavorites', 'property');
        if (this.products !== null && this.properties === null) {
            this.activeGrid = 'products';
        }
        if (this.properties.length !== null && this.products === null) {
            this.activeGrid = 'properties';
        }
    },
}
